
import { defineComponent } from 'vue';

// Mixins
import auth from '@white-label-helper/mixin-auth';
import BackButton from '../back-button/back-button.vue';
import BookingsPreloader from '../bookings-preloader/bookings-preloader.vue';
import {
  BOOKING_STATUSES,
  NAMED_ROUTES,
} from '@white-label-configuration/constants';
import { ManageBookingState } from '@white-label-types/stores';
import { mapState } from 'pinia'
import { useManageBookingStore } from '@white-label-store/manage-booking';
import { TranslateResult } from 'vue-i18n';
import { DATE_TIME_FORMATS, format } from '@white-label-helper/date-utilities';
import BookingDetailsCard from '../booking-details-card/booking-details-card.vue';
import { capitalize } from 'lodash';
import { PiiData, PiiDataGuest } from '@white-label-types/parking-booking';
import ControlButton from '../button/button.vue';
import { postQuestionnaire } from '@white-label-helper/api-manage-booking';
import IconMessage from '../icon-message/icon-message.vue';

export default defineComponent({
  name: 'BookingGuestsInfo',
  components: {
    IconMessage,
    ControlButton,
    IconAlert: () => import('@white-label-icon/icon-alert'),
    BookingDetailsCard,
    BookingsPreloader,
    BackButton,
  },
  mixins: [auth],
  computed: {
    ...mapState(useManageBookingStore,
      [ 'readId',
        'readBooking',
        'readManageBookingItems',
        'readBookingBaggageConfig',
        'readEntryExitInfo'
      ]),
    getId() {
      const id = this.$cookies.get('manageBookingToken')?.id;
      return id || this.readId;
    },
    order(): ManageBookingState['manageBookingState'] {
      return this.readBooking;
    },
    entryExitInfo(): ManageBookingState['manageBookingState']['entryExitInfo'] {
      return this.readEntryExitInfo;
    },
    bookingItem() {
      return (
        this.readManageBookingItems.find(
          (booking) => booking.id === this.bookingId
        ) || {}
      );
    },
    piiData(): PiiData {
      return (
        this.order.piiData.find(
          (piiData) => piiData.bookingItemId === this.bookingId
        ) ?? {
          bookingItemId: 0,
          guests: [],
          status: '',
          token: '',
          vault: '',
        }
      );
    },
    bookingId(): number {
      return this.$cookies.get('guestPiiData').orderId;
    },
    isInboundProduct(): boolean {
      return this.bookingItem?.inventory_item.direction === 'inbound';
    },
    flightNumber(): string {
      const flight = this.isInboundProduct
        ? this.order.user?.inbound_flight
        : this.order.user?.outbound_flight;

      return flight || '-';
    },
    leadGuestData() {
      return {
        title: `${this.$t('guestDetails.guest')} 1`,
        subtitle:
          this.piiData.guests[0].first_name && this.piiData.guests[0].last_name
            ? `${this.piiData.guests[0].first_name} ${this.piiData.guests[0].last_name}`
            : capitalize(this.piiData.guests[0].group),
        piiToken: this.piiData.guests[0].id,
        status: this.piiData.guests[0].status,
      };
    },
    guestsCards() {
      if (this.piiData.guests.length === 1) {
        return [];
      }
      const slicedGuests = this.piiData.guests.slice(1);

      const groupedData = slicedGuests.reduce((acc, obj) => {
        const key = obj.group;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});

      const uniqueGroups = Object.keys(groupedData);
      const renderedData = [];
      let totalGuests = 2;
      uniqueGroups.forEach((group) => {
        groupedData[group].forEach((item: PiiDataGuest) => {
          renderedData.push({
            title: `${this.$t('guestDetails.guest')} ${totalGuests++}`,
            subtitle:
              item.first_name && item.last_name
                ? `${item.first_name} ${item.last_name}`
                : capitalize(item.group),
            piiToken: item.id,
            status: item.status,
            guestType: group,
          });
        });
      });

      const adult = renderedData.filter((el) => el.guestType === 'adult');
      const senior = renderedData.filter((el) => el.guestType === 'senior');
      const child = renderedData.filter((el) => el.guestType === 'child');
      const infant = renderedData.filter((el) => el.guestType === 'infant');

      const orderedArray = [...adult, ...senior, ...child, ...infant];

      return orderedArray;
    },
    allowDetailsSubmission() {
      return (
        this.piiData.guests.every(
          ({ status }) => status === BOOKING_STATUSES.complete
        ) && this.baggageDetailsStatus === BOOKING_STATUSES.complete
      );
    },
    bookingBaggageDetails() {
      return this.readBookingBaggageConfig;
    },
    baggageDetailsStatus() {
      return this.bookingBaggageDetails
        ? BOOKING_STATUSES.complete
        : BOOKING_STATUSES.incomplete;
    },
    getGuestsInfo(): string {
      const groupsObj = {};
      for (const [group, value] of Object.entries(
        this.bookingItem.inventory_option?.details?.groups
      )) {
        groupsObj[group] = (value.totals || {}).quantity || 0;
      }
      const guestsInfo = [];
      for (const [group, value] of Object.entries(groupsObj)) {
        if (value) {
          guestsInfo.push(`${value} x ${capitalize(group)}`);
        }
      }
      return guestsInfo.join(', ');
    },
    hasPassedDeadline() {
      return this.bookingItem.hasPassedDeadline;
    },
    infoMessage() {
      return {
        type: this.hasPassedDeadline ? 'warning' : 'info',
        text: this.$t('guestDetails.requiredToCompleteInfo'),
        iconClass: this.hasPassedDeadline
          ? 'text-orange-accent'
          : 'text-brand-100',
      };
    },
  },
  methods: {
    navigateBack() {
      const orderHasMultipleItems = this.order.items.length === 1;
      if (orderHasMultipleItems) {
        this.$router.push(NAMED_ROUTES.home);
      } else {
        this.$router.push(NAMED_ROUTES.booking_products.home);
      }
    },
    goToBookingHome() {
      this.$router.push(NAMED_ROUTES.home);
    },

    getFlightTime(flightDateTime: string): TranslateResult | string {
      if (!flightDateTime) {
        return '';
      }
      const flightTime = format(
        new Date(flightDateTime),
        DATE_TIME_FORMATS.day_month_year_day_time_am_pm_lower_case
      );

      return this.$t('UI.productSummaryCard.info.flightTime', { flightTime });
    },
    goToBaggageDetailsPage() {
      this.$router.push(NAMED_ROUTES.booking_baggage_details.home);
    },
    goToQuestionnairePage(piiToken: string) {
      this.$cookies.set('guestPiiData', {
        piiToken: piiToken,
        orderId: this.getId,
      });
      this.$router.push(NAMED_ROUTES.booking_guest_details.questionnaire);
    },
    submitPiiQuestionnaire() {
      if (!this.allowDetailsSubmission) return;

      postQuestionnaire(this.getToken, {
        order_item_id: this.getId,
        vault: 'booking-post-sales',
      }).then(() => {
        this.goToBookingHome();
      });
    },
  },
});
