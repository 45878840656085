
import { defineComponent } from 'vue';
import { BOOKING_STATUSES } from '@white-label-configuration/constants';
import { mapState } from 'pinia'
import { useManageBookingStore } from '@white-label-store/manage-booking';
import Button from '../button/button.vue';
import { getAppHeroProduct } from '@white-label-helper/get-app-hero-product';

type BookingStatus = typeof BOOKING_STATUSES[keyof typeof BOOKING_STATUSES];

export default defineComponent({
  name: 'ManageBookingCancelButtons',

  components: {
    ControlButton: Button,
  },

  props: {
    isLoading: {
      type: Boolean,
    },
    disableCancel: {
      type: Boolean,
    },
  },

  computed: {
    ...mapState(useManageBookingStore,['readBookingStatus']),
    orderStatus(): BookingStatus {
      return this.readBookingStatus;
    },

    orderHasMultipleItems() {
      // TODO: Remove condition when ECM_2_CROSS_SELL_POST_PURCHASE is enabled
      return (
        (getAppHeroProduct('parking') === 'parking' &&
        this.$launchDarkly.variation('ECM_2_CROSS_SELL_POST_PURCHASE')) || this.isMultiBasket
      )
    },

    isButtonDisabled(): boolean {
      return !this.orderHasMultipleItems && this.orderStatus !== BOOKING_STATUSES.CONFIRMED;
    },
  },

  methods: {
    cancelBookingHandler(): void {
      this.$emit('cancel-booking');
    },

    keepBookingHandler(): void {
      this.$emit('keep-booking');
    },
  },
});
