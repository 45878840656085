import DOMPurify from 'dompurify';

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('rel', 'noopener');
  }
});

export function sanitizeHTML(html: string) {
  return DOMPurify.sanitize(html, { ADD_ATTR: ['target'] });
}
