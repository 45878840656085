
// Vue
import { defineComponent, PropType } from 'vue';

// Helpers
import { PRODUCT_TYPES } from '@white-label-configuration/constants';

// Types
import type { CartItem } from '@white-label-types/parking-checkout';

// Components
import InfoGuests from './../sub-components/guests-info.vue';
import BundleCardEntry from './bundle-card-entry.vue'
import BundleCardExit from './bundle-card-exit.vue'

export default defineComponent({
  name: 'BundleCardItem',

  components: {
    IconShield: () => import('@white-label-icon/icon-shield'),
    InfoGuests,
    BundleCardEntry,
    BundleCardExit,
  },

  props: {
    bundleItem: {
      type: Object as PropType<CartItem>,
        required: true,
    },
  },

  data() {
    return {
      PRODUCT_TYPES,
    };
  },
});
