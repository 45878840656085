import type { SearchCriteria } from '@white-label-types/parking-booking';
import { httpProduct } from '@white-label-configuration/http';
import { datadogRum } from '@datadog/browser-rum';

// @ts-expect-error property
httpProduct.onRequest((config) => {
  const session_id = datadogRum?.getInternalContext()?.session_id || null;
  config.headers['X-Datadog-Session-ID'] = session_id;
});

export const searchProductAvailability = (criteria: SearchCriteria) => (
  httpProduct.post('search/availability', { body: criteria })
);

export const getProductSearchResults = (searchId: string) => (
  httpProduct.get(`search/availability/${searchId}`)
);

export const getVehicleDetails = (licenceNumber: string) => (
  httpProduct.get(`vehicle/lookup/${licenceNumber}`)
);
