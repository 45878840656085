
// Vue
import { defineComponent } from 'vue';

// Helpers
import { formatPrice } from '@white-label-helper/helper-payment';

export default defineComponent({
  name: 'BundleCardHeading',

  props: {
    name: {
      type: String,
      required: true,
    },

    total: {
      type: Number,
      required: true,
    }
  },

  methods: {
    formatPrice,
  },
});
