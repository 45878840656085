
// Vue
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BundleCardCancellationProtection',

  components: {
    IconShield: () => import('@white-label-icon/icon-shield'),
  },
});
