
import { defineComponent } from 'vue';
import { gaDataLayerTimeslotStep2Values } from '@white-label-helper/ga-data-layer';

export default defineComponent({
  name: 'RadioButton',

  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },

    option: {
      type: [String, Boolean],
      required: true,
      default: '',
    },

    color: {
      type: String,
      default: 'brand',
    },

    width: {
      type: [String, Number],
      default: 20,
    },

    value: {
      type: [String, Boolean],
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(value) {
      this.initial = value;
    }
  },
  data() {
    return {
      initial: this.value,
    };
  },
  methods: {
    onChange(value: string) {
      this.$emit('input', value);
    },
  },
});
