import { render, staticRenderFns } from "./bundle-card-item.vue?vue&type=template&id=3e36a45a&scoped=true"
import script from "./bundle-card-item.vue?vue&type=script&lang=ts"
export * from "./bundle-card-item.vue?vue&type=script&lang=ts"
import style0 from "./bundle-card-item.vue?vue&type=style&index=0&id=3e36a45a&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e36a45a",
  null
  
)

export default component.exports