import { defineStore } from 'pinia';
import { fetchCartItems, AddToCartData, addToCart, deleteCartItem, fetchCart} from '@white-label-helper/api-parking-cart';
import useDepositStore from '@white-label-store/deposits';

import type { SearchCriteria } from '@white-label-types/product-types';
import type {
    ProductOptions,
    GenerateProduct,
    ExtrasCancellationProtectionProduct,
    BookingFee,
    Totals
} from '@white-label-types/parking-booking';
import type { Bundle } from '@white-label-types/parking-checkout';
interface CartProduct extends GenerateProduct{
    seach_criteria: SearchCriteria;
}

export type ProductsType = CartProduct | ExtrasCancellationProtectionProduct;

export type CartStoreState = {
    token: string | null;
    items: (ProductsType)[];
    bundles: Bundle[];
    cancellationProtection: null | ProductOptions['details']['cancellation_protection'] | ExtrasCancellationProtectionProduct['cancellation_protection']
    addedExtras: string[];
    totals: null | Totals;
    bookingFee: null | BookingFee;
    itemIndex: null | number;
    itemListName: string;
    lastKnownFilter: string;
    isMultiBasket: boolean;
    latestItem: null|ProductsType;
    cartUpdated: boolean;
    cartLoading: boolean;
}
/**
 * Cart Store definition
 *
 *  @returns {StoreDefinition}
 */

export const useCartStore = defineStore({
    id: 'cart',

    actions:{
        commitStoreItem(itemToStore: ProductsType) {
            // Prevent adding duplicates commitStoreItem array.
            const index = this.items.findIndex(item => item.id === itemToStore.id);

            if (index !== -1) {
                Object.assign(this.items[index], itemToStore);
            } else {
                this.items.push(itemToStore);
            }
           this.latestItem = itemToStore;
        },
        commitStoreItemList(items: ProductsType[]) {
            this.cartUpdated = false;
            this.items = items
        },
        commitStoreBundles(bundles: Bundle[]) {
            this.bundles = bundles;
        },
        commitIsMultiBasket(payload: boolean) {
            this.isMultiBasket = payload;
        },
        commitCartUpdated(payload: boolean) {
            this.cartUpdated = payload;

        },
        commitStoreTotals(payload: Totals) {
            this.totals = payload;
        },
        commitStoreBookingFee(payload: BookingFee) {
            this.bookingFee = payload;
        },
        storeLatestItem(payload: ProductsType) {
            this.latestItem = payload;
        },
        commitStoreToken(payload: string) {
            this.token = payload;
        },
        commitClearCart(newToken: string | null = null) {
            this.items = [];
            this.token = newToken;
            this.cancellationProtection = null;
            this.addedExtras = [];
            this.totals = null;
        },
        commitStoreCancellationProtection(payload) {
            this.cancellationProtection = payload;
        },
        commitAddExtra(id: string) {
            this.addedExtras.push(id);
        },
        commitRemoveExtra(id: string) {
            this.addedExtras = this.addedExtras.filter((i) => i !== id);
        },
        commitStoreItemIndex(index: number) {
            this.itemIndex = index;
        },
        commitStoreItemListName(listName: string) {
            this.itemListName = listName;
        },
        commitLastKnownFilter(filter: string) {
            this.lastKnownFilter = filter;
        },
        removeCartItem(id: string) {
            this.items = this.items.filter((item) => item.id !== id);
        },
        commitCartLoading(isLoading: boolean) {
            this.cartLoading = isLoading;
        },
        async dispatchFetchCartItems() {
            try {
                if (this.token === null) {
                    throw new Error('No token provided');
                }
                const cartData = await fetchCartItems(this.token);
                const deposit = useDepositStore();
                deposit.updateCartDepositTotal({
                    ...cartData.payable,
                });
                deposit.updateItemDeposit(cartData.items);
                this.commitStoreItem(cartData.items[0]);
                this.commitStoreTotals(cartData.totals);
                this.commitStoreBookingFee(cartData.booking_fee);
                this.commitStoreCancellationProtection(cartData.items[0].cancellation_protection);
                this.commitStoreBundles(cartData.bundles);
            } catch (e) {
                this.commitClearCart()
                throw e;
            }
        },
        async dispatchFetchCart() {
            if (this.token === null) {
                throw new Error('No token provided');
            }
            this.commitCartLoading(true);
            try {
                const {items, totals } = await fetchCart(this.token);
                this.commitStoreItemList(items);
                this.commitStoreTotals(totals);
            } catch (e) {
                throw new Error(e);
            } finally {
            this.commitCartLoading(false);
            }

        },
        async dispatchAddToCart(data: AddToCartData) {
            try {
              const { items, totals, booking_fee } = await addToCart(data);

              const item = items[items.length - 1];
              this.commitStoreItem(item);
              this.commitStoreTotals(totals);
              this.commitCartUpdated(true);

              const cancellationProtection = item?.inventory_option?.details?.cancellation_protection;
              const isCancellationProtectionAvailable = cancellationProtection?.is_available;

              if (isCancellationProtectionAvailable) {
                this.commitStoreBookingFee(booking_fee);
                this.commitStoreCancellationProtection(cancellationProtection);
              }

              return { items, totals, booking_fee };
            } catch (error) {
              console.error('Error adding to cart:', error);
              throw new Error(error instanceof Error ? error.message : String(error));
            }
          },
        async dispatchRemoveFromCart(itemId: string): Promise<void> {
            if (!this.token) {
                throw new Error('No token provided');
            }

            try {
                const { items, totals } = await deleteCartItem(this.token, itemId);
                this.removeCartItem(itemId);

                if (items.length === 0) {
                    this.commitClearCart();
                }

                this.commitStoreTotals(totals);
            } catch (error) {
                console.error('Error removing item from cart:', error);
                throw error;
            }
        },
        readOrCreateCartToken(uuid: string): string {
            let cartToken = this.readToken;
            if (!cartToken) {
                cartToken = uuid;
                this.commitStoreToken(cartToken)
            }
            return cartToken;
        }
    },

    getters: {
        readCartItems: (state) => state.items,
        readBundles: (state) => state.bundles,
        readToken: (state) => state.token,
        readTotals: (state) => state.totals,
        readBookingFee: (state) => state.bookingFee,
        readAddedExtras: (state) => state.addedExtras,
        readCancellationProtection: (state) => state.cancellationProtection,
        readItemIndex: (state) => state.itemIndex,
        readItemListName: (state) => state.itemListName,
        readLastKnownFilter: (state) => state.lastKnownFilter,
        readCartUpdated: (state) => state.cartUpdated,
        readLatestItem: (state) => state.latestItem,
        readCartLoading: (state) => state.cartLoading,
    },
    state: (): CartStoreState => ({
        token: null,
        items: [],
        bundles: [],
        cancellationProtection: null,
        addedExtras: [],
        totals: null,
        bookingFee: null,
        itemIndex: null,
        itemListName: '',
        lastKnownFilter: '',
        isMultiBasket:false,
        latestItem: null,
        cartUpdated:false,
        cartLoading:false,
    }),
    persist: true,
})
