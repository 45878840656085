
// Vue
import { defineComponent, PropType } from 'vue';

// Types
import type { Bundle } from '@white-label-types/parking-checkout';

// Components
import BundleCardHeading from './bundle-card-sub-components/bundle-card-heading.vue'
import BundleCardItem from './bundle-card-sub-components/bundle-card-item.vue'
import BundleCardCancellationProtection from './bundle-card-sub-components/bundle-card-cancellation-protection.vue'

export default defineComponent({
  name: 'ProductSummaryBundleCard',

  components: {
    BundleCardHeading,
    BundleCardItem,
    BundleCardCancellationProtection,
  },

  props: {
    bundle: {
      type: Object as PropType<Bundle>,
      required: true,
    },
  },

  computed: {
    hasCancellationProtection(): boolean {
      return this.bundle.items.some((bundleItem) => bundleItem.is_protected);
    },
  },
});
