// Packages
import { datadogRum } from '@datadog/browser-rum';

// Types
import type { BookingOrderBody, UpdateBookingOrderBody } from '@white-label-helper/api-parkings-bookings';
import type { default as Pusher } from 'pusher-js';

// Constants
import { pusherErrorTimeout } from '@white-label-configuration/constants';

// Helpers
import { handlePaymentRequestOriginal, handlePaymentRequestManageBooking, handleBookingWithoutPayment, handleAmendBookingWithoutPayment } from '@white-label-helper/api-parkings-bookings';
import getPusherChannel from '@white-label-helper/pusher-channel';
import { addCustomMetric } from '@white-label-helper/shared-helpers';

export type PusherEvent = {
  status: string;
  step: string;
  forward: {
    saga_id: string;
    saga_message_id: string;
  };
  payment_client_secret?: string;
}

let channel: Pusher;

function handlePaymentError(error: Error, startTime: number, reject?: (reason?: Error) => void | null, timeoutId: NodeJS.Timeout) {
  clearTimeout(timeoutId);

  addCustomMetric("error", "Booking Metric: Exception", {
    failure_response: error instanceof Error ? error.message : String(error),
    event: 'BookingFailed'
  }, startTime, 'booking_service');

  datadogRum.addError(`pusher-handle-payment-request~handlePayment ${error}`);

  if(reject) {
    reject(error);
  }
}

export function handlePaymentHelper(flowKey: 'original' | 'manageBooking') {
  let eventName: string;

  switch (flowKey) {
    case 'original': {
      eventName = 'OrderCreatingFailedEvent';
      break;
    }
    case 'manageBooking': {
      eventName = 'OrderUpdatingFailedEvent';
      break;
    }
    default: break;
  }

  let channelName: string;
  let timeoutId: NodeJS.Timeout;

  function destroyHandlePayment() {
    if (channel === undefined) {
      channel = getPusherChannel();
    }

    channel.unsubscribe(channelName);
  }

  function handleBookingOrderWithoutPayment(cartToken: string, paymentData: BookingOrderBody | UpdateBookingOrderBody) {
    const startTime = performance.now();
    return new Promise<PusherEvent | void>((resolve, reject) => {
      channelName = `order.${cartToken}`;

      if ('original_order_item_id' in paymentData) {
        handleAmendBookingWithoutPayment({ ...paymentData, send_distribution_api_emails: true })
          .then(() => {
            resolve();
          })
          .catch((error: Error) => {
            handlePaymentError(error, startTime, reject, timeoutId);
          });
      } else {
        handleBookingWithoutPayment({ ...paymentData, send_distribution_api_emails: true })
          .then(() => {
            resolve();
          })
          .catch((error: Error) => {
            handlePaymentError(error, startTime, reject, timeoutId);
          });
      }
    }).catch((error: unknown) => {
      datadogRum.addError(`pusher-handle-payment-request~handleBookingOrderWithoutPayment: ${error}`);
    });
  }

  function handlePayment(cartToken: string, paymentData: BookingOrderBody | UpdateBookingOrderBody) {
    const startTime = performance.now();
    return new Promise<PusherEvent>((resolve, reject) => {
      channelName = `order.${cartToken}`;

      if (channel === undefined) {
        channel = getPusherChannel();
      }

      const localPusher = channel.subscribe(channelName);

      localPusher.bind('OrderPostAuthorizedStatusEvent', (data: PusherEvent) => {
        resolve(data);
      });

      localPusher.bind('error', (error: unknown) => {
        reject(error);
      });

      localPusher.bind(eventName, (error: unknown) => {
        clearTimeout(timeoutId);
        reject({ errorType: eventName, error });
      });

      if ('original_order_item_id' in paymentData) {

        handlePaymentRequestManageBooking(paymentData)
          .catch((error: Error) => {
            handlePaymentError(error, startTime, reject, timeoutId);
          });
      } else {
        handlePaymentRequestOriginal(paymentData)
          .catch((error: Error) => {
            handlePaymentError(error, startTime, reject, timeoutId);
          });
      }

      // Default timeout to reject request if it's frozen
      timeoutId = setTimeout(() => {
        const oneSecond = 1000;
        reject(new Error(`Timed out after ${pusherErrorTimeout / oneSecond} seconds`));
      }, pusherErrorTimeout);
    }).catch((error: unknown) => {
      datadogRum.addError(`pusher-handle-payment-request~handlePayment ${error}`);
      handlePaymentError(error, startTime, null, timeoutId);
      return Promise.reject(error);;
    });
  }

  return {
    handlePayment,
    destroyHandlePayment,
    handleBookingOrderWithoutPayment,
  };
}
