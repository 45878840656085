import defaultsDeep from 'lodash/defaultsDeep';
import { Options } from './axios-config';
import BaseConfig from './http.base-instance-config';
import { getAppVariable } from '@white-label-helper/get-app-variable';
//@ts-expect-error - cannot find module export isURL
import isURL from 'validator/lib/isURL';
import { datadogRum } from '@datadog/browser-rum';

const { interceptors } = BaseConfig;
const regionConfig = getAppVariable('regional_config');
const appVersion = getAppVariable('api_version');
const baseURL = `${regionConfig.domains.user}/${appVersion}`;

if (!isURL(baseURL)) {
  // log error if url is not valid
  console.error(`User API Base URL is not valid. Provided url: ${baseURL}`);
  datadogRum.addError(new Error('User API Base URL is not valid'), {
    baseURL,
    partnerName: getAppVariable('partner_name'),
    partnerId: getAppVariable('partner_id'),
  });
}

interface UserInstance extends Options {
  baseURL: string | undefined;
}

const options: UserInstance = defaultsDeep(
  {
    baseURL,
    headers: {
      Authorization: `Bearer ${process.env['NUXT_ENV_AFFILIATE_APPLICATION_TOKEN']}`,
    },
  },
  BaseConfig.options
);

export default {
  interceptors,
  options,
};
