import { datadogRum } from '@datadog/browser-rum';
import type { GtmInjected } from '@white-label-types/nuxtjs-gtm';

export function trackCheckoutStep(gtm: GtmInjected, step: number, option: string) {
  gtm.push({
    'event': 'checkout',
    'ecommerce': {
      'checkout': {
        'actionField': { 'step': step, 'option': option },
      },
    },
  });
}

type ContextValue = string | number | undefined | Record<string, string | number | undefined>;;

interface TrackActionArgs {
  action: string;
  context: Record<string, ContextValue>
}

export function trackAction(action: TrackActionArgs['action'], context: TrackActionArgs['context']) {
  datadogRum.addAction(action,
    context
  );
}
