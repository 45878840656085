

// See https://magusa.atlassian.net/wiki/spaces/PT/pages/779255824/Google+Tag+Manager
// for details on config options for GTM

export const GTM_IDS_BY_HERO_PRODUCT_TYPE = {
  'lounges': 'GTM-NZLF7FM3',
  'parking': 'GTM-WCM3ZFH',
}

export const GTM_URLS_BY_ID = {
  [GTM_IDS_BY_HERO_PRODUCT_TYPE['lounges']]: 'https://gtm-nlpw7m7s-zwe4y.uc.r.appspot.com/gtm.js',
  [GTM_IDS_BY_HERO_PRODUCT_TYPE['parking']]: 'https://gtm-k6m576t-otk0n.uc.r.appspot.com/gtm.js',
}
