// Packages
import { datadogRum } from '@datadog/browser-rum';

// Types
import type { ContinueBookingBody } from '@white-label-helper/api-parkings-bookings';
import type { default as Pusher } from 'pusher-js';

// Constants
import { pusherErrorTimeout } from '@white-label-configuration/constants';

// Helpers
import { continueBookingRequest } from '@white-label-helper/api-parkings-bookings';
import getPusherChannel from '@white-label-helper/pusher-channel';
import { addCustomMetric } from '@white-label-helper/shared-helpers';

type PusherEvent = {
  management_token: {
    token: string,
    expiration: number
  },
  order_reference: string;
}

let channel: Pusher;

function handlePaymentError(error: Error, startTime: number, reject: (reason?: Error) => void, timeoutId?: NodeJS.Timeout) {
  clearTimeout(timeoutId);

  addCustomMetric("error", "Booking Metric: Exception", {
    failure_response: error instanceof Error ? JSON.stringify(error.message) : JSON.stringify(error),
    event: 'BookingFailed'
  }, startTime, 'booking_service');

  datadogRum.addError(`pusher-handle-payment-request~handlePayment ${error}`);

  reject(error);
}

export function continueBookingHelper(flowKey: 'original' | 'manageBooking') {
  let successEventName: string;
  let failedEventName: string;

  switch (flowKey) {
    case 'original': {
      successEventName = 'OrderCompletedEvent';
      failedEventName = 'OrderCreatingFailedEvent';
      break;
    }
    case 'manageBooking': {
      successEventName = 'OrderAmendmentCompletedEvent';
      failedEventName = 'OrderUpdatingFailedEvent';
      break;
    }
    default: break;
  }

  let channelName: string;
  let timeoutId: NodeJS.Timeout;

  function destroyContinueBooking() {
    if (channel === undefined) {
      channel = getPusherChannel();
    }

    channel.unsubscribe(channelName);
  }

  function continueBooking(cartToken: string, body: ContinueBookingBody) {
    const startTime = performance.now();
    return new Promise<PusherEvent>((resolve, reject) => {

      channelName = `order.${cartToken}`;

      if (channel === undefined) {
        channel = getPusherChannel();
      }

      const localPusher = channel.subscribe(channelName);

      localPusher.bind(successEventName, (data: PusherEvent) => {
        addCustomMetric("tracking", "Booking Metric: Success", {
          event: 'BookingSuccess'
        }, startTime, 'booking_service');
        resolve(data);
      });

      localPusher.bind(failedEventName, (error: Error) => {
        handlePaymentError(error, startTime, reject, timeoutId);
      });

      localPusher.bind('error', (error: Error) => {
        handlePaymentError(error, startTime, reject, timeoutId);
      });

      continueBookingRequest(body).catch((error: Error) => {
        handlePaymentError(error, startTime, reject, timeoutId);
      });

      // Default timeout to reject request if it's frozen
      timeoutId = setTimeout(() => {
        const oneSecond = 1000;
        reject(new Error(`Timed out after ${pusherErrorTimeout / oneSecond} seconds`));
      }, pusherErrorTimeout);
    }).catch((error: unknown) => {
      datadogRum.addError(`pusher-continue-booking: ${JSON.stringify(error)}`);
    });
  }

  return {
    continueBooking,
    destroyContinueBoooking: destroyContinueBooking,
  };
}
