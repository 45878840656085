
import { defineComponent } from 'vue';
import appleEnglish from "./assets/apple/en-GB-add-to-apple-wallet.svg";
import appleGerman from "./assets/apple/de-DE-add-to-apple-wallet.svg";
import appleSpanish from "./assets/apple/es-ES-add-to-apple-wallet.svg";
import appleFrench from "./assets/apple/fr-FR-add-to-apple-wallet.svg";
import appleItalian from "./assets/apple/it-IT-add-to-apple-wallet.svg";
import appleDutch from "./assets/apple/nl-NL-add-to-apple-wallet.svg";
import applePolish from "./assets/apple/pl-PL-add-to-apple-wallet.svg";
import applePortugueseEu from "./assets/apple/pt-PT-add-to-apple-wallet.svg";

import googleEnglish from "./assets/google/en-GB-add-to-google-wallet.svg";
import googleGerman from "./assets/google/de-DE-add-to-google-wallet.svg";
import googleSpanish from "./assets/google/es-ES-add-to-google-wallet.svg";
import googleFrench from "./assets/google/fr-FR-add-to-google-wallet.svg";
import googleItalian from "./assets/google/it-IT-add-to-google-wallet.svg";
import googleDutch from "./assets/google/nl-NL-add-to-google-wallet.svg";
import googlePolish from "./assets/google/pl-PL-add-to-google-wallet.svg";
import googlePortugueseEu from "./assets/google/pt-PT-add-to-google-wallet.svg";

type Language = 'english' | 'german' | 'spanish' | 'french' | 'italian' | 'dutch' | 'polish' | 'portugueseEu';

type VariantAssets = {
  [key: string]: {
    [key in Language]: string;
  };
};

const variantAssets: VariantAssets = {
  apple: {
    english: appleEnglish,
    german: appleGerman,
    spanish: appleSpanish,
    french: appleFrench,
    italian: appleItalian,
    dutch: appleDutch,
    polish: applePolish,
    portugueseEu: applePortugueseEu,
  },
  google: {
    english: googleEnglish,
    german: googleGerman,
    spanish: googleSpanish,
    french: googleFrench,
    italian: googleItalian,
    dutch: googleDutch,
    polish: googlePolish,
    portugueseEu: googlePortugueseEu,
  }
}

type walletVariant = 'apple' | 'google';

export default defineComponent({
  name: 'AddToSmartWalletButton',
  props: {
    ticketDownloadLink: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
      validator: (value: string): value is walletVariant => {
        return value === 'apple' || value === 'google';
      }
    },
  },
  computed: {
    svgSource(): string {
      const assetsByPlatform = variantAssets[this.variant];
      switch (this.$i18n.locale) {
        case 'de-DE':
          return assetsByPlatform.german;
        case 'es-ES':
          return assetsByPlatform.spanish;
        case 'fr-FR':
          return assetsByPlatform.french;
        case 'it-IT':
          return assetsByPlatform.italian;
        case 'nl-NL':
          return assetsByPlatform.dutch;
        case 'pl-PL':
          return assetsByPlatform.polish;
        case 'pt-PT':
          return assetsByPlatform.portugueseEu;
        default:
          return assetsByPlatform.english;
      }
    },
    walletLink(): string {
      return `${this.ticketDownloadLink}&type=${this.variant}wallet`
    },
    altTagText(): string {
      return `Button showing the add to ${this.variant} wallet logo`;
    }
  },
});
