export const mockCartBundles = [
  {
    "id": 1,
    "name": 'T2 Parking Premium',
    "totals": {
      "total": 153.02,
    },
    "items": [
      {
        "id": "2",
        "search_criteria": {
          "parking": {
            "date1": "2024-11-30",
            "time1": "11:00",
            "date2": "2024-12-10",
            "time2": "11:00",
          }
        },
        "product_code": "parking",
        "inventory_item": {
          "name": "T2 Parking",
        },
        "is_protected": false,
      },
      {
        "id": "3",
        "search_criteria": {
          "lounges": {
            "date1": "2024-11-30",
            "time1": "12:00",
            "groups": {
              "senior": 1
            },
          }
        },
        "product_code": "lounges",
        "inventory_item": {
          "name": "T2 Lounge",
        },
        "inventory_option": {
          "sub_options": [
            {
              "details": {
                "name": "senior",
                "name_formatted": "Senior"
              },
              "totals": {
                "quantity": 1
              },
            }
          ]
        },
        "is_protected": true,
      },
      {
        "id": "4",
        "search_criteria": {
          "fasttrack": {
            "date1": "2024-12-17",
            "time1": "12:15",
            "date2": "2024-12-24",
            "time2": "11:45",
            "groups": {
              "adult": 1,
              "senior": 1
            },
          }
        },
        "product_code": "fasttrack",
        "inventory_item": {
          "name": "Security FastTrack T2",
        },
        "inventory_option": {
          "sub_options": [
            {
              "details": {
                "name": "senior",
                "name_formatted": "Senior"
              },
              "totals": {
                "quantity": 1
              },
            }
          ]
        },
        "is_protected": false,
      },
    ],
  }
]
