import { defineStore } from 'pinia';
import { datadogRum } from '@datadog/browser-rum';

import { EmailVerifyPayload, UserPayload, emailVerification, userDetails } from '@white-label-helper/api-parkings-user';
import { getVehicleDetails } from '@white-label-helper/api-parkings-product';

type PatchUserDetailsParams = {
  bearerToken: string
  payload: UserPayload,
}

type ResendVerficationParams = {
  bearerToken: string
  payload: EmailVerifyPayload,
}

type UserInfoMarketingChannels = {
  sms?: boolean,
  email?: boolean
}

type UserInfoPhoneDetails = {
  phoneNumber: string
  phoneNumberWithDialingCode: string
  dialingCode: string
  isoCode: string
}

type UserInfoVehicleDetails = {
  registrationNumber: string
  make: string
  model: string
  colour: string
}

type UserInfoStoreState = {
  email: string;
  firstName: string;
  lastName: string;
  marketingChannels: UserInfoMarketingChannels;
  phoneDetails: UserInfoPhoneDetails;
  postalCode: string;
  vehicleDetails: UserInfoVehicleDetails;
}

/**
 * User Info Store Definitions
 *
 * @returns {StoreDefinition}
 */
export const useUserInfoStore = defineStore({
  id: 'userInfo',

  actions: {

    /**
     * Gets the user details
     *
     * @param bearerToken
     */
    async fetchUserDetails(bearerToken: string) {
      let response;

      try {
        response = await userDetails.get(bearerToken);
      }

      catch (error) {
        if (error instanceof Error) {
          throw error;
        }
        console.error('Error getting user details', error);
        throw new Error('Unable to revover, see above error for details');
      }

      // Email & Name details
      this.email = response.body.data.email;
      this.firstName = response.body.data.first_name;
      this.lastName = response.body.data.last_name;

      // Marketing channels
      this.marketingChannels = response.body.data.marketing_channels ?? {};

      // Phone details
      this.phoneDetails.phoneNumber = response.body.data.phone.phone_number;
      this.phoneDetails.phoneNumberWithDialingCode = response.body.data.phone.formatted_number;
      this.phoneDetails.dialingCode = response.body.data.phone.country_code;
      this.phoneDetails.isoCode = response.body.data.phone.country_iso_code ?? '';

      // Postal Code
      this.postalCode = response.body.data.zipcode ?? '';

      // Vehicle details
      let registrationNumber = ''
      let make = ''
      let model = ''
      let colour = ''

      vehicleDetailsGuard: {
        if (response.body.data.vehicles === null) break vehicleDetailsGuard;
        if (response.body.data.vehicles.length === 0) break vehicleDetailsGuard;

        registrationNumber = response.body.data.vehicles[0].registration_number;
        make = response.body.data.vehicles[0].make;
        model = response.body.data.vehicles[0].model;
        colour = response.body.data.vehicles[0].colour;
      }

      this.vehicleDetails.registrationNumber = registrationNumber;
      this.vehicleDetails.make = make;
      this.vehicleDetails.model = model;
      this.vehicleDetails.colour = colour;

      return {
        channel_id: response.body.data.channel_id,
      };
    },


    /**
     * Patches the user details
     *
     * @param patchUserDetailsParams
     */
    async updateUserDetails({ bearerToken, payload }: PatchUserDetailsParams) {
      let response;

      try {
        response = await userDetails.update(payload, bearerToken);
      }

      catch (error) {
        if (error instanceof Error) {
          throw error;
        }
        console.error('Error patching user details', error);
        throw new Error('Unable to revover, see above error for details');
      }

      // Email & Name details
      this.email = payload.email;
      this.firstName = payload.first_name;
      this.lastName = payload.last_name;

      // Marketing channels
      this.marketingChannels = payload.marketing_channels ?? {};

      // Phone details
      this.phoneDetails.phoneNumber = payload.phone.phone_number;
      this.phoneDetails.phoneNumberWithDialingCode = payload.phone.formatted_number;
      this.phoneDetails.dialingCode = payload.phone.country_code;
      this.phoneDetails.isoCode = payload.phone.country_iso_code;

      // Postal Code
      this.postalCode = payload.zipcode;

      // Vehicle details
      let registrationNumber = ''
      let make = ''
      let model = ''
      let colour = ''

      vehicleDetailsGuard: {
        if (payload.vehicles === undefined) break vehicleDetailsGuard;
        if (payload.vehicles.length === 0) break vehicleDetailsGuard;

        registrationNumber = payload.vehicles[0].registration_number;
        make = payload.vehicles[0].make;
        model = payload.vehicles[0].model;
        colour = payload.vehicles[0].colour;
      }

      this.vehicleDetails.registrationNumber = registrationNumber;
      this.vehicleDetails.make = make;
      this.vehicleDetails.model = model;
      this.vehicleDetails.colour = colour;

      return response.status
    },


    async changeUserPassword(bearerToken: string) {
      let response;

      try {
        response = await userDetails.updatePassword(bearerToken);
      }

      catch (error) {
        if (error instanceof Error) {
          throw error;
        }
        console.error('Error changing user password', error);
        throw new Error('Unable to revover, see above error for details');
      }

      return response.body.data.ticket;
    },


    async resendVerificationEmail({ bearerToken, payload }: ResendVerficationParams) {
      let response;

      try {
        response = await emailVerification.resendVerification(payload, bearerToken);
      }

      catch (error) {
        if (error instanceof Error) {
          throw error;
        }
        console.error('Error resending verification email', error);
        throw new Error('Unable to revover, see above error for details');
      }

      return response.status;
    },

    /**
     * Gets the vehicle details
     *
     * @param licenceNumber
     */
    async fetchVehicleDetails(licenceNumber: string) {
      let response;

      try {
        response = await getVehicleDetails(licenceNumber);
        this.vehicleDetails.make = response?.body?.data?.make;
        this.vehicleDetails.model = response?.body?.data?.model;
        this.vehicleDetails.colour = response?.body?.data?.colour;
        return {
          make: response?.body?.data?.make,
          model: response?.body?.data?.model,
          colour: response?.body?.data?.colour,
        }
      }

      catch (error) {
        datadogRum.addError(error);
      }
    },

  },

  getters: {
    readEmail: (state) => state.email,
    readFirstName: (state) => state.firstName,
    readLastName: (state) => state.lastName,
    readMarketingPreferences: (state) => state.marketingChannels,
    readPhoneNumbers: (state) => state.phoneDetails,
    readPostalCode: (state) => state.postalCode,
    readUserDetails: (state) => state,
    readVehicleDetails: (state) => state.vehicleDetails,
  },

  state: () => {
    const defaultUserInfo: UserInfoStoreState = {
      email: '',
      firstName: '',
      lastName: '',

      marketingChannels: {},

      phoneDetails: {
        phoneNumber: '',
        phoneNumberWithDialingCode: '',
        dialingCode: '',
        isoCode: ''
      },

      postalCode: '',

      vehicleDetails: {
        registrationNumber: '',
        make: '',
        model: '',
        colour: '',
      },
    }

    return defaultUserInfo
  }
});
