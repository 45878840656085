
// Packages
import { defineComponent } from 'vue';
import { mapActions } from 'pinia';
import { datadogRum } from '@datadog/browser-rum';

// Types
import type { TabData } from '@white-label-types/component';
import type { MetaInfo } from 'vue-meta';
import type VueI18n from 'vue-i18n';

// Helpers
import { getAppVariable } from '@white-label-helper/get-app-variable';

// Mixins
import auth from '@white-label-helper/mixin-auth';

// Config
import { GTM_URLS_BY_ID, NAMED_ROUTES } from '@white-label-configuration/constants';

// Store
import { useHelpersStore } from '@white-label-store/helpers';
import { EcomFooter, ModalContainer, Navbar, NotificationContainer } from 'feature-shared-organisms';
import { DOMAINS_NAMES, getDomainUrl } from '@white-label-helper/switch-app';

export default defineComponent({
  components: {
    FooterParkings: EcomFooter,
    ModalContainer,
    NavbarParkings: Navbar,
    NotificationContainer,
  },

  mixins: [auth],

  head(): MetaInfo {
    return {
      title: this.createTabTitle,
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      script: [
        {
          json: this.$partnerConfig as { [key: string]: any },
          type: 'application/json',
          vmid: 'partner-channel-config',
        },
      ]
    };
  },

  computed: {
    /**
     * Gets the name of the page/route, if for some reason the page doesn't have one default to an empty string
     */
    getRouteName(): string {
      return this.$route.name ?? '';
    },

    /**
     * Gets the translated page title based on the route name from the language file "pages".
     */
    getPageName(): VueI18n.TranslateResult {
      return this.$t(`pages.${this.getRouteName}`);
    },

    /**
     * Combines the page name with the partner name to make the page tab title
     * @example View booking | Dev Partner
     */
    createTabTitle(): string {
      let pageName = this.getPageName;
      // Adding a check, so we can capture any pages that don't have an entry within the language file
      if (typeof pageName !== 'string') {
        datadogRum.addError(
          new Error(`${this.getRouteName} doesn't have a tab title`)
        );
      }

      pageName = typeof this.getPageName === 'string' ? this.getPageName : '';
      return `${pageName} | ${getAppVariable('partner_name')}`;
    },

    tabsData(): TabData {
      return {
        tabsLinks: {
          bookingLink: NAMED_ROUTES.home,
          myProfileLink: NAMED_ROUTES.my_profile,
        },
      };
    },

    partner_name() {
      return getAppVariable('partner_name');
    },

    logoURL() {
      return getAppVariable('logo');
    },

    logoRedirectURL() {
      return (
          getAppVariable('logo_redirect_url')
          || getDomainUrl(DOMAINS_NAMES.ECOMMERCE)
      );
    },
  },

  mounted() {
    this.storeScreenSize();
    window.addEventListener('resize', this.storeScreenSize);

    // load GTM
    const gtmId = this.$partnerConfig ? this.$partnerConfig.gtm_id as string : process.env.NUXT_ENV_GTM_ID as string;
    const scriptURL = GTM_URLS_BY_ID[gtmId];
    this.$bootstrapGTM({ id: gtmId, scriptURL });
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.storeScreenSize);
  },

  methods: {
    ...mapActions(useHelpersStore, {
      commitScreenSize: 'commitScreenSize',
    }),

    storeScreenSize(): void {
      this.commitScreenSize(window.innerWidth);
    },
  },
});
