
// Vue
import { defineComponent, PropType } from 'vue';

// Helpers
import { PRODUCT_TYPES } from '@white-label-configuration/constants';
import { formatWithLocale, DATE_TIME_FORMATS } from '@white-label-helper/date-utilities';

// Types
import type { CartItem } from '@white-label-types/parking-checkout';

export default defineComponent({
  name: 'BundleCardEntry',

  props: {
    bundleItem: {
      type: Object as PropType<CartItem>,
        required: true,
    },
  },

  data() {
    return {
      PRODUCT_TYPES,
    };
  },

  computed: {
    entryText(): string {
      let entry = this.bundleItem?.details?.entry_date ?? this.bundleItem?.inventory_item?.entry_date;

      if (!entry && this.bundleItem?.search_criteria) {
        entry = this.bundleItem?.search_criteria[this.bundleItem.product_code].date1 + ' ' + this.bundleItem?.search_criteria[this.bundleItem.product_code].time1;
      }

      return formatWithLocale(
        new Date(entry),
        DATE_TIME_FORMATS.weekday_month_day_year_am_pm_lower_case,
        this.$i18n.locale,
      );
    },
  }
});
