
// Packages
import { defineComponent, PropType } from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

// Components
import TextField from '../text-field/text-field.vue';
import PhoneField from '../phone-field/phone-field.vue';

// Types
import type {
  FormSetup,
  UpdateDetailsValidationMessages,
} from '@white-label-types/user-details';
import type { User } from '@white-label-types/parking-shared';

import {
  alphaNumSpace,
  requiredIf,
  maxLength
} from '@white-label-helper/vuelidate';

// Plugins
import { getFeatureFlag } from '@white-label-plugin/launch-darkly';

type UpdateDetailsFormSetup = FormSetup<User, UpdateDetailsValidationMessages>;

export default defineComponent({
  name: 'FormUpdateDetails',

  components: {
    TextField,
    PhoneField,
  },

  props: {
    onSuccess: {
      type: Function,
      required: true,
    },

    formSetup: {
      type: Object as PropType<UpdateDetailsFormSetup>,
      required: true,
    },
    listofAdditionaFields: {
      type: Object,
      default: () => {},
    },
  },

  validations() {
    const defaultValidations =  {
      formData: {
        ...this.formSetup.validations,
        license_plate: {
          required: requiredIf(() => {
            return this.licensePlateOptions.isRequired;
          }),
          alphaNumSpace(val: string) {
            // Fix when an empty field is considered an error
            // We have a separate validation to check if this field is required
            if (val) {
              return alphaNumSpace(val);
            }
            return true;
          },
        },
        phone: {
          phone_number: {
            required: requiredIf(() => {
              return this.phoneNumberOptions.isRequired;
            }),
            isValid(): boolean {
              const phoneNumber = this.$v.formData.phone.phone_number.$model;
              const isNotEmpty = phoneNumber && phoneNumber.length > 0;
              return isNotEmpty ? this.phoneValid : true;
            }
          },
        },
        zipcode: {
          required: requiredIf(() => {
            return this.postalAddressOptions.isRequired;
          }),
        },
        outbound_flight: {
          required: requiredIf(() => {
            return this.outboundFlightOptions.isRequired;
          }),
        },
        inbound_flight: {
          required: requiredIf(() => {
            return this.inboundFlightOptions.isRequired;
          }),
        },
      },
    };

    if(this.isVehicleFieldsFeatureFlagEnabled) {
      defaultValidations.formData.vehicle_make = {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.vehicleMakeOptions.isRequired;
        }),
        maxLength: maxLength(this.vehicleFieldsMaxLength)

      }
      defaultValidations.formData.vehicle_model = {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.vehicleModelOptions.isRequired;
        }),
        maxLength: maxLength(this.vehicleFieldsMaxLength)
      }
      defaultValidations.formData.vehicle_colour= {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.vehicleColourOptions.isRequired;
        }),
        maxLength: maxLength(this.vehicleFieldsMaxLength)
      }
    }

    return defaultValidations
  },

  data() {
    return {
      phoneValid: false,
      formData: {
        first_name: '',
        last_name: '',
        outbound_flight: null,
        inbound_flight: null,
        zipcode: null,
        license_plate: null,
        vehicle_make: null,
        vehicle_model: null,
        vehicle_colour: null,

        phone: {
          phone_number: null,
          formatted_number: null,
          country_code: null,
        },
      },

      defaultFormData: {
        first_name: '',
        last_name: '',
        outbound_flight: null,
      },
    }
  },

  computed: {
    formDataChanged(): boolean {
      return !isEqual(this.formData, this.defaultFormData);
    },
    formHasErrors(): boolean {
      return this.$v.$error
    },
    validationMessages(): UpdateDetailsValidationMessages {
      return this.formSetup.validationMessages;
    },
    licensePlateOptions() {
      return this.listofAdditionaFields['plate_number'] ?? {
        toShow: false,
        isRequired: false
      };
    },
    phoneNumberOptions() {
      return this.listofAdditionaFields['phone_number'] ?? {
        toShow: false,
        isRequired: false
      };
    },
    postalAddressOptions() {
      return this.listofAdditionaFields['zipcode'] ?? {
        toShow: false,
        isRequired: false
      };
    },
    outboundFlightOptions() {
      return this.listofAdditionaFields['outbound_flight_number'] ?? {
        toShow: false,
        isRequired: false
      };
    },
    inboundFlightOptions() {
      return this.listofAdditionaFields['inbound_flight_number'] ?? {
        toShow: false,
        isRequired: false
      };
    },
    vehicleMakeOptions() {
      return this.listofAdditionaFields['vehicle_make'] ?? {
        toShow: false,
        isRequired: false
      };
    },
    vehicleModelOptions() {
      return this.listofAdditionaFields['vehicle_model'] ?? {
        toShow: false,
        isRequired: false
      };
    },
    vehicleColourOptions() {
      return this.listofAdditionaFields['vehicle_colour'] ?? {
        toShow: false,
        isRequired: false
      };
    },
    isVehicleFieldsFeatureFlagEnabled():boolean {
      return getFeatureFlag('GT_2824_DAILY_HOURLY_BOOKING_REPORTS_ADDITIONAL_INFORMATION')
    },
    vehicleFieldsMaxLength(){
      return 25
    }
  },

  watch: {
    formDataChanged() {
      this.$v.$touch();
      this.onFormDataChanged();
    },
    formHasErrors() {
      this.onFormDataChanged();
    }
  },

  mounted() {
    this.defaultSetupFormData();
    this.setUpFormSubmitListener();
  },

  beforeDestroy() {
    this.setDownFormSubmitListener();
  },

  methods: {
    defaultSetupFormData() {
      // @ts-ignore - Prop type incompatible with data
      this.formData = cloneDeep(this.formSetup.formData);
      // @ts-ignore - Prop type incompatible with data
      this.defaultFormData = cloneDeep(this.formSetup.formData);
      this.onFormDataChanged();
    },

    onFormDataChanged() {
      this.$emit('form-data-changed', {
        formDataChanged: this.formDataChanged,
        hasErrors: this.formHasErrors,
      });
    },

    onFormFieldInput() {
      this.$v.$touch();
      this.onFormDataChanged();
    },

    validateForm(successCallback: Function) {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$v.$reset();
        successCallback();
      }
    },

    setUpFormSubmitListener() {
      this.$on('validate', () => {
        const user = this.formData;
        this.validateForm(() => this.onSuccess(user));
      });
    },

    setDownFormSubmitListener() {
      this.$off('validate', () => {
        this.validateForm(() => this.onSuccess(this.formData));
      });
    },
  },
});
