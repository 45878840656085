
// Vue
import { defineComponent, PropType } from 'vue';

// Helpers
import { PRODUCT_TYPES } from '@white-label-configuration/constants';
import { formatWithLocale, DATE_TIME_FORMATS } from '@white-label-helper/date-utilities';

// Types
import type { CartItem } from '@white-label-types/parking-checkout';

export default defineComponent({
  name: 'BundleCardExit',

  props: {
    bundleItem: {
      type: Object as PropType<CartItem>,
        required: true,
    },
  },

  data() {
    return {
      PRODUCT_TYPES,
    };
  },

  computed: {
    exitText(): string {
      let entry = this.bundleItem?.details?.exit_date ?? this.bundleItem?.inventory_item?.exit_date;

      if (!entry && this.bundleItem?.search_criteria) {
        entry = this.bundleItem?.search_criteria[this.bundleItem.product_code].date2 + ' ' + this.bundleItem?.search_criteria[this.bundleItem.product_code].time2;
      }

      return formatWithLocale(
        new Date(entry),
        DATE_TIME_FORMATS.weekday_month_day_year_am_pm_lower_case,
        this.$i18n.locale,
      );
    },
  }
});
