import { render, staticRenderFns } from "./bundle-card-heading.vue?vue&type=template&id=75d52293"
import script from "./bundle-card-heading.vue?vue&type=script&lang=ts"
export * from "./bundle-card-heading.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports