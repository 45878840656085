
import { defineComponent, PropType } from 'vue';
// Store
import { mapState } from 'pinia';
import { useManageBookingStore } from '@white-label-store/manage-booking';
import { ManageBookingState } from '@white-label-types/stores';

import { trackAction } from '@white-label-helper/shared-helpers';
import { print } from '@white-label-helper/print';
import auth from '@white-label-helper/mixin-auth';

import BTicketShareButton from '../b-ticket-share-button/b-ticket-share-button.vue';
import ManageBookingModalEmailReceipt from '../manage-booking-modal-email-receipt/manage-booking-modal-email-receipt.vue';

export default defineComponent({
  name: 'ManageBookingShareButton',

  components: {
    BTicketShareButton,
    ManageBookingModalEmailReceipt,
  },

  mixins: [auth],
  props: {
    orderData: {
      type: Object as PropType<ManageBookingState['manageBookingState']>,
      required: true,
    },
    shouldAlignLeft: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      displayEmailReceiptModal: false,
    };
  },

  computed: {
    ...mapState(useManageBookingStore,
      [
        'readIsPastBooking',
        'readUserEmail',
      ]
    ),
    isPastBooking() {
      return this.readIsPastBooking;
    },

    getUserEmail(): ReturnType<typeof readUserEmail> {
      return this.readUserEmail;
    },

    canResendConfirmationEmail(): boolean {
      // Authenticated users can resend past booking confirmations via `/account/receipt/resend`
      // Unauthenticated users can not resend past booking confirmations via `/booking/receipt/resend`
      return this.isAuth0UserLoggedIn || !this.isPastBooking;
    },

    orderEmailOptions(): Record<string, string>[] {
      const auth0UserEmail =
        this.isAuth0UserLoggedIn && this.auth0User
          ? (this.auth0User.email as string)
          : '';

      const orderEmail = this.getUserEmail || '';

      // In future the user may be able to store multiple email addresses.
      // For now provide logged in user's email if available, or the order email as a fallback.
      if (auth0UserEmail) {
        return [{ value: auth0UserEmail }];
      }
      if (orderEmail) {
        return [{ value: orderEmail }];
      }
      return [];
    },
  },

  methods: {
    closeModal(): void {
      trackAction('Share Button', {
        close_email_receipt_modal: {
          modal_closed: this.displayEmailReceiptModal === false,
        },
      });
      this.displayEmailReceiptModal = false;
    },

    printBookingReceipt(): void {
      trackAction('Share Button', {
        print_booking_receipt: {
          window_print: true,
        }
      });
      print();
    },

    openEmailReceiptModal(): void {
      trackAction('Share Button', {
        open_email_receipt_modal: {
          modal_opened: this.displayEmailReceiptModal === true,
        }
      });
      this.displayEmailReceiptModal = true;
    },
  },
});
